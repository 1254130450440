{
  "-": "-",
  "hello world": "hello world",

  "Value is required": "Value is required",
  "Value is not a valid email address": "Value is not a valid email address",
  "The value must be equal to the Email value": "The value must be equal to the Email value",
  "The value must be equal to the Password value": "The value must be equal to the Password value",
  "This field should be at least 6 long": "This field should be at least 6 long",
  "This field should be at least 6 characters long": "This field should be at least 6 characters long",
  "Nombre": "Name",
  "Email": "Email",
  "Confirmar email": "Confirm email",
  "Contraseña": "Password",
  "Confirmar contraseña": "Confirm password",
  "Creá tu cuenta": "Create your account",
  "Ya tengo cuenta": "Already have account",
  "Registrarme": "Register",

  "login.error": "Failed to log in. Verify your email and password, and try again",

  "order_status_open": "Open",
  "order_status_closed": "Closed",
  "order_status_cancelled": "Cancelled",
  "order_status_paid": "Paid",
  "order_status_pending": "Pending",
  "order_status_refunded": "Refunded",
  "order_status_voided": "Voided",
  "order_status_unpacked": "Unpacked",
  "order_status_packed": "Packed",
  "order_status_shipped": "Shipped",
  "order_status_unshipped": "Unshipped",
  "order_status_ship_unpacked": "Unpacked",
  "order_status_ship_packed": "Packed",
  "order_status_ship_shipped": "Shipped",
  "order_status_ship_unshipped": "Unshipped",
  "order_status_pickup_unpacked": "Unpacked",
  "order_status_pickup_packed": "Packed",
  "order_status_pickup_shipped": "Picked Up",
  "order_status_pickup_unshipped": "Not Picked Up",

  "Locale": "Local",

  "month": "month",
  "cardNumber is empty.": "Card number is empty.",
  "cardNumber should be a number.": "Card number should be a valid number.",
  "cardNumber should be of length between '8' and '19'.": "Card number should be of length between 8 and 19.",
  "expirationMonth is empty.": "Expiration month is empty.",
  "expirationMonth should be a number.": "Expiration month should be a valid number.",
  "expirationMonth should be a value from 1 to 12.": "Expiration month should be a value from 1 to 12.",
  "expirationYear is empty.": "Expiration year is empty.",
  "expirationYear should be of length '2'.": "Expiration year should be of length 2.",
  "expirationYear should be a number.": "Expiration year should be a valid number.",
  "expirationYear value should be greater or equal than 2022.": "Expiration year value should be greater or equal than 2022.",
  "expirationYear value should be greater or equal than 2023.": "Expiration year value should be greater or equal than 2023.",
  "securityCode is empty.": "Security code is empty.",
  "securityCode should be of length '3' or '4'.": "Security code should be of length 3 or 4.",
  "securityCode should be a number.": "Security code should be a valid number.",

  "approved": "approved",
  "accredited": "accredited",
  "in_process": "in process",
  "offline_process": "offline process",
  "rejected": "rejected",
  "cc_rejected_high_risk": "high risk",

  "customer_category": "Customer Category",
  "product_category": "Product Category",
  "product": "Product",
  "customer": "Customer",
  "promotion": "Promotion",
  "plural_customer_category": "Customer Categories",
  "plural_product_category": "Product Categories",
  "plural_product": "Products",
  "plural_customer": "Customers",
  "plural_promotion": "Promotions",

  "normal": "normal",
  "Normal": "Normal",
  "preorder": "preorder",
  "Preorder": "Preorder",
  "delayed": "delayed",
  "Delayed": "Delayed",
  "personalizado": "custom",
  "Personalizado": "Custom",

  "Básico": "Basic",
  "Basic": "Basic",
  "básico": "Basic",
  "basic": "Basic",
  "Profesional": "Professional",
  "Professional": "Professional",
  "profesional": "Professional",
  "professional": "Professional",
  "Empresarial": "Business",
  "Business": "Business",
  "empresarial": "Business",
  "business": "Business",
  "Mas allá": "Beyond",
  "Beyond": "Beyond",
  "mas allá": "Beyond",
  "beyond": "Beyond",

  "file": "File",
  "cancel": "Cancel",
  "pay": "Pay",
  "pack": "Pack",
  "ship": "Ship",
  "hide": "Hide",
  "show": "Show",
  "delete": "Delete",
  "activate": "Activate",
  "deactivate": "Deactivate",
  "hideInstallments": "Hide installments",
  "showInstallments": "Show installments"
}
