<template>
  <div
    class="modal z-[50]"
    :class="{ 'modal-open': layoutSidebarNotificationsOpen }"
    @click="closeModal"
  >
    <div
      class="sidebarNotifications fixed left-[70%] flex h-screen w-[-webkit-fill-available] flex-col overflow-y-auto bg-base-300 shadow-xl drop-shadow-2xl"
    >
      <div
        class="mb-5 flex w-full items-center justify-between bg-base-100 p-4"
      >
        <h2 class="text-2xl">Novedades</h2>
        <button
          v-if="layoutSidebarNotificationsOpen"
          @click="closeSidebarNotifications"
        >
          <icon-component name="XMark" type="solid" classes="w-7 mt-1 mr-1" />
        </button>
      </div>
      <div v-if="notifications && !notifications.length > 0">
        <p class="text-center text-info">No tenés notificaciones pendientes!</p>
      </div>
      <div v-else class="flex flex-col gap-2">
        <div
          v-for="notification in notifications"
          style="visibility: inherit"
          class="collapse-arrow card collapse mx-3 border bg-base-100 shadow-xl"
          :class="{
            'collapse-open':
              !notification.is_dismissed ||
              expandedNotifications == notification.id,
            'border-base-300': notification.is_dismissed,
            'border-info': !notification.is_dismissed,
          }"
        >
          <h4
            class="card-title px-3 py-2 text-lg"
            :class="{
              'collapse-title cursor-pointer': notification.is_dismissed,
              'alert-info': !notification.is_dismissed,
            }"
            @click="toggleNotificationExpand(notification.id)"
          >
            {{ notification.title }}
          </h4>
          <div class="collapse-content pt-3">
            <span
              v-if="
                !notification.is_dismissed ||
                expandedNotifications == notification.id
              "
              class="text-end text-xs text-accent"
            >
              {{ new Date(notification.created_at).toLocaleDateString() }}
            </span>
            <p>{{ notification.content }}</p>
            <div
              class="card-actions mt-3 items-center"
              :class="{
                'justify-between':
                  !notification.is_dismissed && notification.link,
                'justify-end': !notification.is_dismissed && !notification.link,
              }"
            >
              <a
                v-if="notification.link"
                :href="notification.link"
                target="_blank"
                class="link-hover link-accent link"
              >
                <button
                  class="btn min-h-8 h-8 bg-gradient-to-r from-primary to-accent text-black"
                >
                  {{ notification.cta }}
                </button>
              </a>
              <div class="flex flex-col gap-2">
                <button
                  v-if="!notification.is_dismissed"
                  class="btn btn-outline btn-xs max-w-max"
                  @click="dismissNotification(notification.id)"
                >
                  Marcar leída
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Imports
import { computed, onMounted, ref } from "vue";
import { useLayout } from "@/stores/layout";
import axios from "axios";
import { useNotifications } from "@/stores/notifications";

// Variables
const layout = useLayout();
const notificationService = useNotifications();
const notifications = ref([]);
const loading = ref(false);
const expandedNotifications = ref(0);

// Computed
const layoutSidebarNotificationsOpen = computed(
  () => layout.isSidebarNotificationsOpen
);

// Methods
const getNotifications = async () => {
  loading.value = true;

  await axios
    .get("get_notifications")
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.notifications &&
        response.data.notifications.data &&
        response.data.notifications.data.length > 0
      ) {
        notifications.value = response.data.notifications.data.sort((a, b) => {
          // Ordeno arriba las no leídas y abajo las leídas
          if (!a.is_dismissed && !b.is_dismissed) {
            // Si ambas son no leídas, ordenar por fecha
            return new Date(b.created_at) - new Date(a.created_at);
          }
          if (a.is_dismissed && b.is_dismissed) {
            // Si ambas son leídas, ordenar por fecha
            return new Date(b.created_at) - new Date(a.created_at);
          }
          if (!a.is_dismissed && b.is_dismissed) {
            // La no leída viene primero
            return -1;
          }
          if (a.is_dismissed && !b.is_dismissed) {
            // La no leída viene primero
            return 1;
          }
        });
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const dismissNotification = async (data) => {
  loading.value = true;
  try {
    const response = await axios.post("notifications/dismiss", {
      dismissed_notifications: data,
    });

    if (response) {
      notificationService.checkExistingNotifications();
      await getNotifications();
    } else {
      console.log("Error while dismissing notification");
    }
  } catch (error) {
    console.log(error);
  }

  loading.value = false;
};

const toggleNotificationExpand = (notificationId) => {
  if (expandedNotifications.value != notificationId) {
    expandedNotifications.value = notificationId;
  } else {
    expandedNotifications.value = "";
  }
};

const closeSidebarNotifications = () => {
  layout.closeSidebarNotifications();
};

const closeModal = (event) => {
  const clickedElement = event.target;
  if (clickedElement.closest(".sidebarNotifications")) {
    return;
  }

  closeSidebarNotifications();
};

// Lifecycle hooks
onMounted(() => {
  getNotifications();
});
</script>
